<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import {} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
},
  data() {
    return {
      title: this.$t('kpi.title'),
      items: [],
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,
      donutChart: {
          series: [16, 55, 29 ],
          chartOptions: {
              labels: ["Gold", "Silver", "Bronze"],
              colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                  show: true,
                  position: 'bottom',
                  horizontalAlign: 'center',
                  verticalAlign: 'middle',
                  floating: false,
                  fontSize: '14px',
                  offsetX: 0
              },
              responsive: [{
                  breakpoint: 600,
                  options: {
                      chart: {
                          height: 240
                      },
                      legend: {
                          show: false
                      },
                  }
              }]
          }
      }
    };
  },
  mounted() {
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 
  },
  methods:{
    
    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },

    
    applyFilter(query){
      
      this.query = query;

      
    },
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal && !this.personalizationId){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-2">
            <h5 class="mb-0 card-title" style="font-size: 17px;">Analytics</h5>
            <p class="text-muted mb-0">Aquí puedes ver como están performando tus personal shoppers.</p>
          </div>
          <div class="text-end w-25">
            <!--<p class="mb-0 text-start">{{$t('reco_analyze.date_range')}}</p>-->
              <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range :format="'DD.MM.YYYY'" :clearable="false" :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
              </date-picker>    
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      Clientes<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="'Cantidad de clientes distintos.'"/>
                    </p>
                    <h4 class="mb-0" v-b-popover.hover.bottom="120">{{ 120}}</h4>
                  </div>
              </div>
              <div class="col-sm-2 text-center" >
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      Conversaciones<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="'Cantidad de conversaciones'"/>
                    </p>
                    <h4 class="mb-0" v-b-popover.hover.bottom="3456">3456</h4>
                  </div>
                </div>
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      Mensajes<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="'Cantidad de mensajes enviados.'"/>
                    </p>
                    <h4 class="mb-0">{{'170'}}</h4>
                  </div>
              </div>
              <div class="col-sm-2 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    Mensajes/Conversacion<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="'Cantidad de canjes entregados.'"/>
                  </p>
                  <h4 class="mb-0" v-b-popover.hover.bottom="`10`">{{ `20` }}</h4>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    Conversion Rate<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="'Tasa de conversión sobre el total de conversaciones.'"/>
                  </p>
                  <h4 class="mb-0">{{'1.3%'}}</h4>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    Revenue Asociado<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="'Revenue asociado a la tasa de conversión'"/>
                  </p>
                  <h4 class="mb-0" v-b-popover.hover.bottom="`123243`">{{`12.5M` }}</h4>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-lg-9">
        <div class="card">
          
        </div>
      </div>
    </div>
</Layout>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}
</style>
